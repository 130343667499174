import * as types from '@/store/action-types';
import {setLocal,getLocal} from '@/utils/local'
import router from '@/router/index'
export default {
    state: {
        demo:'我是user的数据'
    },
    mutations: {
        // 设置用户信息
        demo(){
            console.log('++++')
        },
        [types.SET_USER](state, userInfo) {
            
        },
        [types.SET_PERMISSION](state,has){
            
        },
        [types.SET_MENU_PERMISSION](state,has){
           
        }
    },
    actions: {
        // 用户 =》 action =》 接口 =》 mutation
        async [types.SET_USER_LOGIN]({ commit }, options) {
            // 调用登录接口
           
        },
        async [types.SET_USER_VALIDATE]({commit}){
            
        },
        [types.SET_ROUTE]({commit,state}){
            
        }
    },
}