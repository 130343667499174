export default {
    state: {
      channelList:[]
    },
    mutations: {
      setChannelList(state, data) {
        state.channelList =data;
      },
    },
    actions: {
    },
}
