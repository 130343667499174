export default [
    {
        path: '/',
        name: 'index',
        component: () => import(/*webpackChunkName:'home'*/'@/views/index.vue') // 会默认代码分割
    },
    {
        path:'/House',
        name:'House',
        component:()=>import('@/views/Industry/House.vue')
    },
    {
        path:'/WJgroupIntroduce',
        name:'WJgroupIntroduce',
        component:()=>import('@/views/WJgroup/introduce/index.vue')
    },
    {
        path:'/WJgroupDevelop',
        name:'WJgroupDevelop',
        component:()=>import('@/views/WJgroup/develop/index.vue')
    },{
        path:'/WJgroupHonor',
        name:'WJgroupHonor',
        component:()=>import('@/views/WJgroup/honor/index.vue')
    },

   {
       path:'/WJgroupTeam',
       name:'WJgroupTeam',
       component:()=>import('@/views/WJgroup/team/index.vue')
   },{
       path:'/WJgroupSendWord',
       name:'WJgroupSendWord',
       component:()=>import('@/views/WJgroup/sendWord/index.vue')
   },

   {
       path:'/IndustryHouse',
       name:'IndustryHouse',
       component:()=>import('@/views/Industry/House.vue')
   },
   {
       path:'/IndustryHouseDetail',
       name:'IndustryHouseDetail',
       component:()=>import('@/views/Industry/houseDetail.vue')
   },

   {
       path:'/IndustryCommercial',
       name:'IndustryCommercial',
       component:()=>import('@/views/Industry/Commercial.vue')
   }, {
       path:'/Industry',
       name:'Industry',
       component:()=>import('@/views/Industry/index.vue')
   },{
       path:'/IndustryProperty',
       name:'IndustryProperty',
       component:()=>import('@/views/Industry/Property.vue')
   },
   {
       path:'/IndustryCommerce',
       name:'IndustryCommerce',
       component:()=>import('@/views/Industry/CommerceAndTrade.vue')
   },
   {
       path:'/Information/:Id',
       name:'Information',
       component:()=>import('@/views/Information/index.vue')
   },
   {
       path:'/InformationDetail',
       name:'InformationDetail',
       component:()=>import('@/views/Information/detail.vue')
   },
   {
       path:'/nativeContent',
       name:'NativeContent',
       component:()=>import('@/views/Information/nativeContent.vue')
   },

   {
       path:'/personnel',
       name:'personnel',
       component:()=>import('@/views/personnel/index.vue')
   },
   {
       path:'/personnelStrategy',
       name:'personnelStrategy',
       component:()=>import('@/views/personnel/strategy.vue')
   },
   {
       path:'/personneltalentConcept',
       name:'personneltalentConcept',
       component:()=>import('@/views/personnel/talentConcept.vue')
   },
   {
       path:'/personnelJoinUs',
       name:'personnelJoinUs',
       component:()=>import('@/views/personnel/joinUs.vue')
   },
   {
       path:'/personnelCadreSchool',
       name:'personnelCadreSchool',
       component:()=>import('@/views/personnel/cadreSchool.vue')
   },
   {
       path:'/personnelContact',
       name:'personnelContact',
       component:()=>import('@/views/personnel/contact.vue')
   },{
       path:'/moreSchool',
       name:'moreSchool',
       component:()=>import('@/views/personnel/moreSchool.vue')
   },
   {
       path:'/Entertainment',
       name:'Entertainment',
       component:()=>import('@/views/Entertainment/index.vue')
   },
   {
       path:'/EntertainmentDigitalCinema',
       name:'EntertainmentDigitalCinema',
       component:()=>import('@/views/Entertainment/digitalCinema.vue')
   },
   {
       path:'/EntertainmentMingfang',
       name:'EntertainmentMingfang',
       component:()=>import('@/views/Entertainment/mingfang.vue')
   },{
       path:'/EntertainmentIndustrialPark',
       name:'EntertainmentIndustrialPark',
       component:()=>import('@/views/Entertainment/IndustrialPark.vue')
   },
    {
        path: '*',
        component: () => import(/*webpackChunkName:'404'*/'@/views/404.vue')
    }]
