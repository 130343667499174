<template>
  <div id="app">
     <router-view></router-view>
  </div>
</template>
<script>
  export default {
    created() {
      this.$init.getChannelList();
      if(!localStorage.getItem('language')){
        localStorage.setItem('language','zh')
      }
    }
  }
</script>
<style lang="less">
 @import "./assets/css/reset.less";
 @import "./assets/css/font/iconfont.css";
// #app {
//   text-align: center;

// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
