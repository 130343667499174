import Vue from 'vue'
import VueRouter from 'vue-router'
import hooks from './hooks';

Vue.use(VueRouter)

// 关闭冗余导航报错
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 每个模块有自己的路由配置

// 获取当前对应文件夹下的 所有以router.js 结尾的文件

// files就是一个函数  , false 不去遍历子目录
const files = require.context('./routers', false, /\.router.js$/);
const routes = [];
files.keys().forEach(key => {
  // 获取到文件的内容 拿到默认的导出结果 放到routes里 ， 如果遇到* 号 路由会将* 放到最后面
  routes.push(...files(key).default)
});
console.log(routes);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  },
})

// 需要给路由增加多个钩子 每个钩子实现一个具体功能 beforeEach next
Object.values(hooks).forEach(hook => {
  // 绑定hook中的this是路由的实例
  router.beforeEach(hook.bind(router));
})

export default router