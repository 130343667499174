
export default {
  index: {
    Introduction: '集团介绍',
    honor: '资质荣誉',
    team: '管理团队',
    contactUs: '联系我们',
  },
  introduce:{
    slogan:'维善铸佳 · 明德致远',
    subSlogan1:'维佳集团致力于从城市发展角度，',
    subSlogan2:'探索城市公共生活与空间的需求',
    establish:'成立于{num}年',
    staff:'拥有员工{num}余人',
    custom:'达成客户{num}余家',
    develop:'开发总量突破{num}万方',
    operate:'{num}万方商用物业全自持运营',
    cultureTitle:'企业文化',
    cultureSubtitle1:'使命',
    cultureSubtitle2:'理念',
    cultureSubtitle3:'价值观 ',
    mission:'发展使命',
    missionSub1:'以人为本',
    missionDesc1:'一枝独秀，终不敌满园春光。以“人”为核心，育才以怀才抱器，信与爱兼之，发掘潜力与价值，助推理想之花灼灼绽放。',
    missionSub2:'志同道合',
    missionDesc2:'志合者不惧山海之远，理想为球心，你款款而来，我们不期而遇，携手共进，连成命运共同体。',
    missionSub3:'诚信感恩',
    missionDesc3:'诚信感恩为立身之本，于己、于企业、于社会言必诚信，行必忠正，恩必回馈。',
    missionSub4:'以人为本',
    missionDesc4:'“匠心”之美，美于精雕细琢，美于创新；“匠心”之贵，贵于细节。以“工匠精神”去与时俱进，去精炼技能，去超越自我，去把握无限，去用刹那留住永恒。',
    idea:'企业理念',
    ideaSub1:'维和谐之善举，铸臻善之佳作',
    ideaSub2:'明社会之义德，致企业之远航',
    values:'核心价值观',
    valuesSub1:'服务社会，发展自己是我们的努力方向',
    valuesSub2:'自己发展，回馈社会是我们的永恒主题',
  },
  develop:{
    title:'发展历程'
  },
  honor:{
    title:'资质荣誉'
  },
  team:{
    title:'管理团队',
    desc:'凝聚专业的团队 组建规范高效的管理体系{num} 一群理想主义者的实践脚步\n' +
      '运筹帷幄之间 决胜千里之外',
  },
  property:{
    title:'维佳地产',
    href1:'精品住宅',
    href1Desc:'筑精品名居，铸百年基业，成就千万人的城市梦想。',
    href2:'商业地产',
    href2Desc:'让商业娱乐化，让消费者体验化，让品牌国际化，让享受一站化。',
    href3:'物业服务',
    href3Desc:'客户至上、用心服务。',
    idea:`——维佳地产始终坚持“<b>五品</b>”的筑作理念——`,
    ideaSub:'建高标准的产品、有工匠精神的精品、有创作者的作品、大师级的艺术品、市场化的孤品，让品质先行，用实力铸就品牌。',
    ideaItem1:'建高标准的产品',
    ideaItem2:'工匠精神的精品',
    ideaItem3:'创作者的作品',
    ideaItem4:'大师级的艺术品',
    ideaItem5:'市场化的孤品',
    introduction:'产业介绍',
    introductionDesc:'维佳地产以2004年成立的武汉维佳置业有限公司为伊始，依托集团公司跨越式发展格局，以武汉为核心，逐步实施由传统房地产开发向“生态新城”、“文体新城”、“特色小镇”等多元化方向发展的战略转型。近年来，维佳地产开发总量已突破300万方，产品覆盖住宅、商业、写字楼、酒店等多类业态，其40万方商用物业全自持建设运营，资金积累雄厚。',
    position:'战略定位',
    position1:'生态新城',
    position2:'文体新城',
    position3:'特色小镇',
    positionTypeTitle1:`住宅`,
    positionTypedesc1:`品质铸造，设施齐全`,
    positionTypeTitle2:`写字楼`,
    positionTypedesc2:`核心地段，高端雅致`,
    positionTypeTitle3:`酒店`,
    positionTypedesc3:`温馨舒适，高雅宜人`,
    positionTypeTitle4:`商业`,
    positionTypedesc4:`休闲娱乐，品位生活`,
    positionTypeAll:` 产业<br>覆盖`,
    moreTips:'更多内容 敬请期待',
    purposeTitle:'行业宗旨',
    purposeDesc1:'奉行服务品质为先，',
    purposeDesc2:'塑造企业品牌为重',
    purposet1:'有温度的服务',
    purposetd1:'耕耘细作',
    purposet2:'有个性的项目',
    purposetd2:'不落窠臼',
    purposet3:'有管理的团队',
    purposetd3:'精益求精',
    purposet4:'有智能的硬设',
    purposetd4:'伴您前行',
    houseAddress:'项目地址：',
    housePhone:'联系方式：',


  },
  commerce:{
    title:'维佳商贸',
    brandTitle:'产品展示',
    brand:'合作品牌',
    space:'维佳空间',
    spaceDesc:'WEIGA SPACE正式诞生于武汉，以“先知产品价值、洞察市场潜力、创建商业理念、发展模式裂变”为出发点，以此为始，无限裂变，不断延展和丰富维佳若得宇宙空间元素构建。在奢侈品核心文化和品牌理念的驱使下，通过产品搭配的精致组合来发现品质发现价值。'

  },
  entertainment:{
    title:'维佳文娱',
    advantages:'核心优势'
  },
  park:{
    title:'维佳产业园',
    href1:'西西里汽车工业园',
    href2:'明芳工业园',
    experienceTitle:'HUB式体验中心',
    experienceDesc:'空间·品质·文化·生态·价值',
    introduceTitle:'园区介绍',
    introduceDesc:'西西里汽车工业园总投资达10亿人民币，拟建成集运动休闲、游乐观赏、商业集群、餐饮酒店以及研发中心为一体的大型综合性汽车产业园，结合优越的地理位置、方便的交通、浓厚的人文气息、成熟的商业配套，全方位打造汽车产业集群化汽车工业园。',
    positioningTitle:'产业定位',
    positioningDesc:'将汽车产业、售后服务与毛坦公园、军转公园、商业配套服务相结合，打造产业、科技、休闲、娱乐、服务一体的现代化综合性园区。',
    targetTitle:'建设目标',
    target1:'完善区域业态',
    target2:'增强经济活力',
    target3:'增加就业机会',
    target4:'带动经济发展',
    advantage:'园区优势',
    advantagName1:'产业',
    advantagDesc1:`周边区域现状功能以住宅、教育、商业为主；<br>片区发展趋势优良，系列优质房地产开发项目与多所高校相望。`,
    advantagName2:'交通',
    advantagDesc2:`片区道路网络发达，交通便利；<br>武汉市新能源汽车及中高端品牌的核心汽车产业基地。`,
    advantagName3:'区位',
    advantagDesc3:`在枢纽布局、市场覆盖和地理位置上具有突出优势`,
    advantagName4:'消费人群',
    advantagDesc4:`人口流入量大、结构年轻化，周边常住居民和大学生易撬动汽车消费市场`,
    serviceTitle:'园区服务',
    service1:'汽车服务',
    service2:'运动休闲',
    service3:'儿童游乐',
    service4:'主题酒店',
    service5:'商业生态',
    service6:'创业中心',
    characteristicTitle:'园区特色',
    characteristic1:'覆盖包括新车销售、二手车交易、汽车维修与改装、 进口汽车展销、汽车配件销售等全方位产业链。',
    characteristic2:'为客户提供集商务会谈、运动、游乐、购物、住宿等一站式服务，为客户带来更好的沉浸式体验。',
    characteristic3:'打造集运动、游乐、商业、酒店、研发中心为一体的多功能现代化园区。',
    characteristic4:'引入运动、科技、健康、游乐、时尚的设施设备, 从而改善人们的生活环境，提高生活品质。',
    characteristic5:'作为武汉市首个以军转、运动为核心的 新型产业园，将对武汉市及周边地区经济及相关产业带来新的契机和蓬勃发展。',
    brand:'品牌入驻',
    mfSlogan:'十万方一站式4S店集群',
    mfSubSlogan:'永不落幕的汽车商业之旅',
    mftarget1:'得天独厚的环境',
    mftarget2:'创造有机生长',
    mftarget3:'浑然天成的底蕴',
    mftarget4:'激发无限可能',
    mftarget5:'高标准、高起点的规划建设',
    mftarget6:'追求卓越的发展动力',
    mftarget7:'历久弥新的经营品质',
    mftarget8:'独特、敏锐的发展视角',
    mfIntroduce1:'武汉市明芳经贸有限公司成立于2002年，作为维佳集团成立最早的公司，明芳公司不断为集团壮大发展贡献力量。历经二十载的砥砺前行，明芳公司始终秉持高标准、高起点的规划和建设，传承维佳集团追求卓越的发展动力，坚持历久弥新的经营品质，以其独特的视角，不断促进武汉市汽车产业的发展，目前已是武汉市具有一定影响力的汽车商贸综合市场。',
    mfIntroduce2:'铁机汽车展销中心是武汉二环内唯一最大的汽车商贸服务市场，得天独厚的环境，创造有机生长的汽车商业之城，汽车展销与多业态城市综合体在这里，被全新定义明芳经贸，引领车城时代，畅享汽车生活。现已入驻奔驰、宝马、林肯等知名国际汽车品牌，是武汉市政府城市化发展特别支持项目。',
  },
  news:{
    search:'搜索',
    yearPlaceholder:'筛选年份',
    keywordsPlaceholder:'请输入内容',
    creatTime:'发布时间：',
    source:'来源：维佳集团',
    prev:'上一篇',
    prevTips:'已经是第一篇了',
    next:'下一篇',
    nextTips:'已经是最后一篇了'
  },
  school:{
    title:'干部培训学校',
    slogan:'2021年1月维佳集团启动维佳干部培训学校机构。维佳干部培训学校的创办是维佳集团发展历程上的又一座里程碑，是集团人才战略规划有效落地的创新之举，是孵化经营人才、管理人才、十字型人才的全新摇篮。',
    desc1:'勤于学习',
    desc2:'勇于进取',
    desc3:'敢于担当',
    desc4:'乐于奉献',
    desc5:'严于律己',
    classShow:'课堂展示',
    more:'更多',
    student:'优秀学员'
  },
  joinUs:{
    title:'加入我们',
    search: "搜索公司",
    placeholder:'请输入内容',
    joinEmail:'如果您对相关职位感兴趣，请发送您的简历到招聘邮箱：',
    joinEmailTips:'建议您以该格式命名邮件:“城市+应聘岗位+姓名”',
    joinPhone:'所有应聘者的资料都将被仔细筛选并严格保密。招聘咨询电话：',
    companyType:'公司类型：',
    companyName:'公司名称：',
    all:'全部',
    phone:'联系方式：',
    apply:'申请职位',
    formName:'姓名',
    formNameT:'请输入姓名',
    formPhone:'联系方式：',
    formPhoneT:'请输入联系方式',
    formPost:'应聘职位',
    formPostT:'请输入应聘职位',
    annex:'支持DOC、PDF 文件大小不超过10M',
    annextIip:'上传简历附件',
    annexbTN:'简历附件上传失败',
    success:'简历投递成功',
    btnC:'取 消',
    btnS:'确 定',
    Media:'新媒体矩阵',
  },
  menu:{
    WeChat:'扫码加微信',
    officialAccount:'扫码加公众号',
    filings:'备案号',
    chinese:'简体中文',
    engish:'English',
    index:'首页',
    group:'维佳集团',
    layout:'产业布局',
    news:'资讯中心',
    recruit:'人才窗口',
    Introduction: '集团介绍',
    Message:'董事长寄语',
    develop:'发展历程',
    honour:'资质荣耀',
    team:'管理团队',
    Property:'维佳地产',
    Commerce:'维佳商贸',
    entertainment:'维佳文娱',
    culture:'文化娱乐',
    park:'维佳产业园',
    trends:'集团动态',
    information:'企业资讯',
    Media:'媒体报道',
    talented:'人才理念',
    school:'干部培训学校',
    joinUs:'加入我们',
    detail:'详情',
    cinema:'数字影院',
    Play:'游乐酷玩',
    education:'维佳教育'
  }

}

