<template>
  <div>
    <div class="app_header_box" ref="appHeaderBox">
      <el-row type="flex" justify="center" style="position: relative;z-index: 100000;" >

        <el-col :xs="23" :sm="23" :md="23" :lg="23" :xl="18" ref="appHeader">
          <el-row type="flex" justify="space-between" align='middle' class="hidden-xs">
            <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
              <router-link class="app_header_logo" tag="a" to="/">
                <img class="app_header_logo" style="vertical-align: middle" src="../assets/image/logo.png" alt="">
              </router-link>
            </el-col>
            <!-- pc样式 -->
            <el-col   :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
              <ul class="navTopul flex ">
                <li class="navTopli " :class="pathActive=='1'?'activeNavLi':''">
                  <router-link class="navTopli_menu" tag="a" to="/">
                    {{$t('menu.index')}}
                  </router-link>
                </li>

                <!--                <li v-for="(item,index) in channelList" :key="index" class="navTopli" ref="navTopliOne" :class="pathActive=='2'?'activeNavLi':''"-->
                <!--                    @mousemove="mouseChose(2)" @mouseout="mouseChose(0)">-->
                <!--                  <router-link class="navTopli_menu" tag="a" to="/WJgroupIntroduce">-->
                <!--                    {{item.typename}}-->
                <!--                  </router-link>-->
                <!--                  &lt;!&ndash; 维佳集团 &ndash;&gt;-->
                <!--                  <div class="navTopdiv flex" :class="{navTopdivActive:TopdivActive==2}"-->
                <!--                       :style="{width:widthall,left:'-'+widthOne}">-->
                <!--                    <router-link v-for="(child,c_index) in item.children" :key="c_index" tag="a" to="/WJgroupIntroduce">{{child.typename}}</router-link>-->
                <!--                  </div>-->
                <!--                </li>-->

                <li class="navTopli" ref="navTopliOne" :class="pathActive=='2'?'activeNavLi':''"
                    @mousemove="mouseChose(2)" @mouseout="mouseChose(0)">
                  <router-link class="navTopli_menu" tag="a" to="/WJgroupIntroduce">
                    {{$t('menu.group')}}
                  </router-link>
                  <!-- 维佳集团 -->
                  <div class="navTopdiv flex" :class="{navTopdivActive:TopdivActive==2}"
                       :style="{width:widthall,left:'-'+widthOne}">
                    <router-link  tag="a" to="/WJgroupIntroduce"> {{$t('menu.Introduction')}}</router-link>
                    <router-link tag="a" to="/WJgroupSendWord">{{$t('menu.Message')}}</router-link>
                    <router-link tag="a" to="/WJgroupDevelop">{{$t('menu.develop')}}</router-link>
                    <router-link tag="a" to="/WJgroupHonor">{{$t('menu.honour')}}</router-link>
                    <router-link tag="a" to="/WJgroupTeam">{{$t('menu.team')}}</router-link>
                  </div>
                </li>
                <li class="navTopli" ref="navTopliTwo" :class="pathActive=='3'?'activeNavLi':''"
                    @mousemove="mouseChose(3)" @mouseout="mouseChose(0)">
                  <router-link class="navTopli_menu" tag="a" to="/Industry">
                    {{$t('menu.layout')}}
                  </router-link>
                  <!-- 产业布局 -->
                  <div class="navTopdiv flex" :class="{navTopdivActive:TopdivActive==3}"
                       :style="{width:widthall,left:'-'+widthTwo}">
                    <router-link  tag="a" to="/Industry">{{$t('menu.Property')}}</router-link>
                    <router-link tag="a" to="/EntertainmentIndustrialPark">{{$t('menu.park')}}</router-link>
                    <router-link tag="a" to="/Entertainment">{{$t('menu.entertainment')}}</router-link>
                    <router-link tag="a" to="/IndustryCommerce">{{$t('menu.Commerce')}}</router-link>
                  </div>
                </li>
                <li class="navTopli" ref="navTopliThree" :class="pathActive=='4'?'activeNavLi':''"
                    @mousemove="mouseChose(4)" @mouseout="mouseChose(0)">
                  <router-link class="navTopli_menu" tag="a" to="/Information/1">
                    {{$t('menu.news')}}
                  </router-link>
                  <!--  资讯中心-->
                  <div class="navTopdiv flex" :class="{navTopdivActive:TopdivActive==4}"
                       :style="{width:widthall,left:'-'+widthThree}">
                    <router-link  tag="a" to="/Information/1">{{$t('menu.trends')}}</router-link>
                    <router-link tag="a" to="/Information/2">{{$t('menu.information')}}</router-link>
                    <!-- <router-link tag="a" to="/Information/3">媒体报道</router-link> -->
                    <!-- <router-link tag="a" to="/nativeContent">企业软文</router-link> -->
                  </div>
                </li>
                <li class="navTopli" ref="navTopliFour" :class="pathActive=='5'?'activeNavLi':''"
                    @mousemove="mouseChose(5)" @mouseout="mouseChose(0)">
                  <router-link class="navTopli_menu" tag="a" to="/personneltalentConcept">
                    {{$t('menu.recruit')}}
                  </router-link>
                  <!--  人才招聘-->
                  <div class="navTopdiv flex" :class="{navTopdivActive:TopdivActive==5}"
                       :style="{width:widthall,left:'-'+widthFour}">
                    <router-link  tag="a" to="/personneltalentConcept">{{$t('menu.talented')}}
                    </router-link>
                    <router-link tag="a" to="/personnelCadreSchool">{{$t('menu.school')}}</router-link>
                    <router-link tag="a" to="/personnelJoinUs">{{$t('menu.joinUs')}}</router-link>
                    <!-- <router-link :style="{'margin-left':widthFour }" tag="a" to="/personnel">加入维佳 </router-link>
                     <router-link tag="a" to="/personnelContact">联系方式</router-link> -->
                  </div>
                </li>
              </ul>
            </el-col>


            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="flex justify_end">
              <el-dropdown @command="handleCommand" v-if="langShow">
                  <span class="el-dropdown-link ">
                   <i class="iconfont" style="font-size: 22px">&#xe719;</i> <span>{{language=='zh'?$t('menu.chinese'):$t('menu.engish')}}</span>  <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="zh">{{$t('menu.chinese')}}</el-dropdown-item>
                  <el-dropdown-item command="en">{{$t('menu.engish')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <!-- 手机端导航 -->
          <div class="mb_Box flex justify_between hidden-lg hidden-md hidden-sm">
            <a style="height: 30px;display: inline-block;" href="index.html">
              <img style="height: 30px;" src="../assets/image/logo.png" alt="">
            </a>
            <div class="left_mbbox" @click="openNav()">
              <i class="el-icon-s-fold" v-if="!NavShowMb"></i>
              <i class="el-icon-s-unfold" v-if="NavShowMb"></i>
            </div>
            <ul class="navTopul_mb_Box " v-if="NavShowMb">
              <li class="navTopli_mb " @click="choseMobile(1)">
                <router-link class="navTopli_menu_mb" tag="a" to="/">
                  {{$t('menu.index')}}
                </router-link>
              </li>
              <li class="navTopli_mb" @click="choseMobile(2)">

                <a class="navTopli_menu_mb" tag="a" >
                  {{$t('menu.group')}}
                </a>
                <!-- 维佳集团 -->
                <div class="navTopdiv_mb " v-if="TopdivActive==2">
                  <router-link  tag="a" to="/WJgroupIntroduce"> {{$t('menu.Introduction')}}</router-link>
                  <router-link tag="a" to="/WJgroupSendWord">{{$t('menu.Message')}}</router-link>
                  <router-link tag="a" to="/WJgroupDevelop">{{$t('menu.develop')}}</router-link>
                  <router-link tag="a" to="/WJgroupHonor">{{$t('menu.honour')}}</router-link>
                  <router-link tag="a" to="/WJgroupTeam">{{$t('menu.team')}}</router-link>
                </div>
              </li>
              <li class="navTopli_mb" @click="choseMobile(3)">
                <a class="navTopli_menu_mb" tag="a">
                  {{$t('menu.layout')}}
                </a>
                <!-- 产业布局 -->
                <div class="navTopdiv_mb " v-if="TopdivActive==3">
                  <router-link  tag="a" to="/Industry">{{$t('menu.Property')}}</router-link>
                  <router-link tag="a" to="/EntertainmentIndustrialPark">{{$t('menu.park')}}</router-link>
                  <router-link tag="a" to="/Entertainment">{{$t('menu.entertainment')}}</router-link>
                  <router-link tag="a" to="/IndustryCommerce">{{$t('menu.Commerce')}}</router-link>
                </div>
              </li>
              <li class="navTopli_mb" @click="choseMobile(4)">
                <a class="navTopli_menu_mb">
                  {{$t('menu.news')}}
                </a>
                <!--  资讯中心-->
                <div class="navTopdiv_mb " v-if="TopdivActive==4">
                  <router-link  tag="a" to="/Information/1">{{$t('menu.trends')}}</router-link>
                  <router-link tag="a" to="/Information/2">{{$t('menu.information')}}</router-link>
                </div>
              </li>
              <li class="navTopli_mb" @click="choseMobile(5)" style="border-bottom: 0">
                <a class="navTopli_menu_mb" >
                  {{$t('menu.recruit')}}
                </a>
                <!--  人才招聘-->
                <div class="navTopdiv_mb " v-if="TopdivActive==5">
                  <router-link  tag="a" to="/personneltalentConcept">{{$t('menu.talented')}}
                  </router-link>
                  <router-link tag="a" to="/personnelCadreSchool">{{$t('menu.school')}}</router-link>
                  <router-link tag="a" style="border-bottom: 0" to="/personnelJoinUs">{{$t('menu.joinUs')}}</router-link>
                </div>
              </li>


            </ul>
          </div>
          <!--            -->
        </el-col>
      </el-row>
    </div>
    <div  class="app_header_wrap">

    </div>

  </div>
</template>

<script>


export default {
  name: "headert",
  props: {},
  data() {
    return {
      nowPath: '/',
      pathActive: 1,
      list: {
        '/': 1,
        '/WJgroupIntroduce': 2,
        '/WJgroupDevelop': 2,
        '/WJgroupHonor': 2,
        '/WJgroupTeam': 2,
        '/WJgroupSendWord':2,
        '/Industry':3,
        '/IndustryHouse': 3,
        '/IndustryCommercial': 3,
        '/IndustryProperty': 3,
        '/IndustryCommerce': 3,
        '/Entertainment': 3,
        '/EntertainmentMingfang': 3,
        '/EntertainmentIndustrialPark': 3,
        '/Information/1': 4,
        '/Information/2': 4,
        '/Information/3': 4,
        '/nativeContent': 4,
        '/personnel': 5,
        '/personnelContact': 5,
        '/personnelStrategy': 5,
        '/personnelJoinUs': 5,
        '/personneltalentConcept':5,
        '/personnelCadreSchool':5

      },
      widthOne: "300px",
      widthTwo: '300px',
      widthThree: '300px',
      widthFour: '300px',
      widthall: "300px",
      TopdivActive: 0,
      navShow: true,
      language:'zh',
      langShow:false,
      NavShowMb:false
    }
  },
  computed: {
    channelList() {
      let channelList = [];
      if (this.$store.state.article.channelList && this.$store.state.article.channelList.length > 0) {
        channelList = this.$utils.handleTree(this.$store.state.article.channelList, 'id', 'parent_id');
      }
      return channelList;
    }
  },
  mounted() {
    if(window.localStorage.getItem('language')){
      this.language=window.localStorage.getItem('language')
    }else{
      window.localStorage.setItem('language','zh')
      this.language='zh'
    }
    this.getData()
    // window.addEventListener('scroll', this.handleNavScroll);
    this.nowPath = this.$route.fullPath;
    this.pathActive = this.list[this.nowPath]
    let widthIn = this.$refs.appHeader.$el.offsetLeft
    this.widthall = (this.$refs.appHeaderBox.offsetWidth) + 'px'
    this.widthOne = (this.$refs.navTopliOne.offsetLeft + widthIn) + 'px'
    this.widthTwo = (this.$refs.navTopliTwo.offsetLeft + widthIn) + 'px'
    this.widthThree = (this.$refs.navTopliThree.offsetLeft + widthIn) + 'px'
    this.widthFour = (this.$refs.navTopliFour.offsetLeft + widthIn) + 'px'
  },
  methods: {
    // 头部代码
    choseMobile(index) {
      this.TopdivActive = index

    },
    openNav() {
      console.log(111)
      this.NavShowMb = !this.NavShowMb
      console.log(this.NavShowMb)
    },
    // mouseChose(index) {
    //   this.TopdivActive = index
    // },
    // 头部代码结束
    getData(){
      let api='?m=api&c=v1.Api&a=index&_ajax=1,web_recordnum_en';
      this.$request.post(api,{
        apiGlobal:`ekey=1`, //获取全局变量
      }).then(res=>{
        let siteVar =this.$utils.translate(res.apiGlobal[1].data);
        this.langShow=siteVar.lang_en==1?true:false
        if(!this.langShow){
          //如果隐藏掉中英文切换，就默认显示中文
          window.localStorage.setItem('language','zh')
          this.language='zh'
        }
        console.log(this.siteVar,'---')
      })
    },
    handleCommand(e){
      this.language=e;
      window.localStorage.setItem('language',e)
      window.location.reload();
      // console.log(e)
    },

    mouseChose(index) {
      this.TopdivActive = index
    },
    handleNavScroll() {
      let scrollTopNav = document.documentElement.scrollTop;
      if (scrollTopNav <= 80) {
        this.navShow = true
      } else {
        this.navShow = false
      }
      console.log(document.documentElement.scrollTop);
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleNavScroll);
  }
};
</script>
<style scoped lang="less">
.app_header_box {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
.app_header_logo{
  height: 46px;
}
.app_header_wrap{
  height: 80px;
}

.navTopul {
  height: 100%;


}

.navTopul .navTopli {
  //width: 145px;
  padding:0 20px;
  text-align: center;
  line-height: 80px !important;
  height: 80px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  color: #1F2D3A;
  position: relative;
  /* z-index: 100000; */
}

.navTopul .navTopli .navTopli_menu {
  display: inline-block;
  line-height: 80px !important;
  height: 80px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  color: #1F2D3A;


}

.navTopdiv {
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.7000);
  border-radius: 0px 0px 0px 0px;
  opacity: 0;
  height: 0px;
  /* top: 80px; */
  top: 80px !important;
  transition: 1s;
  /*   transition: top 1s;
  transition: opacity 1s; */
  z-index: -1;
  overflow: hidden;
  text-align: center;
  justify-content: center;
}


.navTopdiv a {
  font-size: 17px;
  /* font-family: OPPOSans-Regular, OPPOSans; */
  font-weight: 400;
  color: #797979;
  padding: 0 18px;
}

.navTopdivActive {
  opacity: 1 !important;
  height: 80px !important;
}
/* transition: myfirst 1s infinite; */
/* z-index: 9 !important; */
.navTopdivActive a {
  line-height: 80px;
}




@keyframes myfirst {
  0% {
    top: 10px !important;
    opacity: 0 !important;
  }

  100% {
    top: 80px !important;
    opacity: 1 !important;
  }
}


.navTopdiv .router-link-exact-active {
  color: #A4825F;
}


.activeNavLi {
  /* background: linear-gradient(-65deg, #957252 0%, #D5B88E 100%); */
  position: relative;
}

.activeNavLi::after {
  content: "";
  width: 85px;
  height: 4px;
  background: linear-gradient(90deg, #D5B88E 0%, #8F6B4B 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  left: 50%;
  margin-left: -42px;
}


.activeNavLi .navTopli_menu {
  /* font-weight: bold;
  color: #FFFFFF !important; */
  text-shadow: 0px 3px 3px rgba(120, 88, 59, 0.43);
}
.el-dropdown-link{
  color: #A4825F;
  font-size: 16px;
  cursor: pointer;
  line-height: 22px;
  span{
    display: inline-block;
    line-height: 24px;
  }
  i{
    vertical-align: middle;
    margin-right: 5px;
  }

}




.mb_Box {
  /* position: relative; */
}

.navTopul_mb_Box {
  position: absolute;
  top: 55Px;
  right: 0;
  width: 100%;
  background-color: #aa8864;
}

.navTopul_mb_Box .navTopli_mb{
  position: relative;
  border-bottom: 1px solid #fff;
}

.navTopul_mb_Box .navTopli_mb a {
  display: inline-block;
  width: 100%;
  height: 40Px;

  font-size: 16Px;
  color: #fff;
  //text-align: center;
  line-height: 40Px;
  background-color: #aa8864;
  padding:0 30Px
}

.navTopul_mb_Box .navTopdiv_mb {
  //position: absolute;
  //top: 0;
  //right: 0Px;
  width: 100%;
}

.navTopul_mb_Box .navTopdiv_mb a {
  height: 40Px;
  border-bottom: 1px solid #fff;
  font-size: 14Px;
  color: #fff;
  //text-align: center;
  padding-left: 60PX;
  line-height: 40Px;
  background-color: #bc9d7c;
}
.navTopul_mb_Box .navTopdiv_mb a:last-child{
  border-bottom: 0;
}
@media screen and (min-width:320px) and (max-width:767px) {
  .app_header_box {
    height: 55Px!important;
  }
  .app_header_wrap{
    height: 55Px!important;
  }
  .mb_Box {
    height: 55Px;
  }
  .seizeASeat {
    height: 55px;
  }
  .left_mbbox i {
    font-size: 24Px;
    color: #aa8864;
    cursor: pointer;
  }
}

@media screen and (min-width:768px) and (max-width:992px) {
  .app_header_box {
    height: 80px!important;
  }
  .app_header_wrap{
    height: 80px!important;
  }
  .mb_Box {
    height: 55px;
  }
  .seizeASeat {
    height: 55px;
  }
  .left_mbbox i {
    font-size: 30px;
    color: #aa8864;
    cursor: pointer;
  }
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .seizeASeat {
    height: 80px;
  }
}

@media screen and (min-width:1201px) and (max-width:1920px) {
  .seizeASeat {
    height: 80px;
  }
}
</style>
