<template>
  <div style="width:100%" class="app_foot_box">
    <el-row type="flex" justify="center">
      <el-col :xs="23" :sm="23" :md="23" :lg="23" :xl="18">
        <div class="foot_box flex justify_between ">
          <div>
            <img class="foot_box_logo" style="" src="../assets/image/flogo.png" alt="">
          </div>
          <div class="flex1 foot_link_wrap" style="padding: 0 20px" >
            <div class="foot_link">
              <router-link tag="a" to="/">
                {{$t('menu.index')}}
              </router-link>/
              <router-link tag="a" to="/WJgroupIntroduce">
                {{$t('menu.group')}}
              </router-link>/
              <router-link tag="a" to="/IndustryHouse">
                {{$t('menu.layout')}}
              </router-link>/
              <router-link tag="a" to="/Information/1">
                {{$t('menu.news')}}
              </router-link>/
              <router-link tag="a" to="/personneltalentConcept">
                {{$t('menu.recruit')}}
              </router-link>
            </div>
            <div class="foot_address  flex ">
              <a style="color: #D8D8D8;" :class="lang=='zh'?'':'aBlock'"><i class="el-icon-phone"></i> {{siteVar.web_attr_1}}</a>
              <a style="color: #D8D8D8;flex: 1" class="address_wrap"><i class="el-icon-location"></i> {{lang=='zh'?siteVar.web_attr_16:siteVar.web_attr_30}}</a>
            </div>
          </div>
          <ul class="weixin_Box flex hidden-xs">
            <li style="margin-left: 0">
              <img :src="siteVar.web_attr_24" alt="">
              <p>  {{$t('menu.WeChat')}}</p>
            </li>
            <li>
              <img :src="siteVar.web_attr_17" alt="">
              <p>{{$t('menu.officialAccount')}}</p>
            </li>
          </ul>
        </div>
        <div class="foot_copyright">
          {{siteVar.web_attr_21}} {{$t('menu.filings')}}:{{siteVar.web_recordnum}}
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>
  export default {
    name: "footert",
    props: {},
    data() {
      return {
        nowPath: '/',
        siteVar:{}
      }
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    mounted() {
      this.nowPath = this.$route.fullPath;
      this.getData()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1,web_recordnum_en';
        this.$request.post(api,{
          apiGlobal:`ekey=1`, //获取全局变量
        }).then(res=>{
          this.siteVar =this.$utils.translate(res.apiGlobal[1].data);
        })
      },
    }
  };
</script>
<style scoped>
  .app_foot_box {
    width: 100%;
    background-color: #384044;
  }

  .foot_link {
    color: #89939a;
    font-size: 18px;
    line-height: 38px;
  }

  .foot_link a {
    color: #D8D8D8;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 18px;
  }

  .foot_link a:first-child {
    padding-left: 0px;
  }

  .foot_address {
    margin-top: 14px;

  }

  .foot_address a {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    margin-right: 15px;

  }

  .foot_address a i {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: #af8e68;
    color: #373f42;
    text-align: center;
    line-height: 27px;
    font-size: 18px;
    margin-right: 10px;
  }

  .foot_box {
    padding: 40px 0;
  }

  .weixin_Box li {
    text-align: center;
    margin-left: 30px;
  }

  .weixin_Box li img {
    width: 82px;
    height: 82px;
  }

  .weixin_Box li p {
    text-align: center;
    margin-top: 12px;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
  }

  .foot_copyright {
    border-top: 1px solid #495155;
    text-align: center;
    font-size: 14px;
    color: #979797;
    line-height: 22px;
    padding: 30px 0;
  }
  .aBlock{
  display: block;
    margin-bottom: 10px;
  }
  .foot_box_logo{
    height: 66px;
  }

  @media screen and (min-width:320px) and (max-width:767px) {
    .foot_copyright{
      line-height: 36px;
      padding-top: 18px;
    }
    .foot_address{
      flex-direction: column;
      align-items: start;

    }
    .foot_box{
      justify-content: center;
    }
    .address_wrap{
      margin-top: 10px;
    }
    .foot_link_wrap{
      padding-right: 0!important;
      flex: unset;

    }
  }

</style>
