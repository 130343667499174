import store from '@/store'
import axios from '@/utils/axios'
import translate from '@/utils/index'
export  default {
  channelQueue:[],
  channelLoading:false,
  getChannelList(callback){
    let channelList =store.state.article.channelList;
    if(!channelList || !channelList.length){
      if(callback){
        this.channelQueue.push(callback);
      }
      if(!this.channelLoading){
        this.channelLoading=true;
        let api='?m=api&c=v1.Api&a=archivesList&_ajax=1';
        axios.post(api,{
          apiChannel_1:`ekey=1&type=all`
        }).then(res=>{
          this.channelLoading=false;

          let channelList = translate.translate(res.apiChannel[1].data);

          store.commit('article/setChannelList' ,channelList)
          if(this.channelQueue && this.channelQueue.length>0){
            this.channelQueue.forEach(item=>{
              item(channelList);
            })
            this.channelQueue=[];
          }
        })
      }

    }else{
      callback&&callback(channelList);
    }
  }
}
