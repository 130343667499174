export const SET_BANNER_LIST = 'SET_BANNER_LIST';


// 通过登录成功后 将返回的结果放到userInfo中

export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const SET_USER = 'SET_USER'
export const SET_USER_VALIDATE = 'SET_USER_VALIDATE'
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_MENU_PERMISSION = 'SET_MENU_PERMISSION'
export const SET_ROUTE = 'SET_ROUTE'; // 设置路由  (最终mutation只改变状态 业务代码都放到action中)


// 需要存放token 

export const SET_REQUEST_TOKEN = 'SET_REQUEST_TOKEN'




