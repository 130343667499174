export default {
  /**
   * 构造树型结构数据
   * @param {*} data 数据源
   * @param {*} id id字段 默认 'id'
   * @param {*} parentId 父节点字段 默认 'parentId'
   * @param {*} children 孩子节点字段 默认 'children'
   */
  handleTree(data, id, parentId, children) {
    let config = {
      id: id || 'id',
      parentId: parentId || 'parentId',
      childrenList: children || 'children'
    };

    var childrenListMap = {};
    var nodeIds = {};
    var tree = [];

    for (let d of data) {
      let parentId = d[config.parentId];
      if (childrenListMap[parentId] == null) {
        childrenListMap[parentId] = [];
      }
      nodeIds[d[config.id]] = d;
      childrenListMap[parentId].push(d);
    }

    for (let d of data) {
      let parentId = d[config.parentId];
      if (nodeIds[parentId] == null) {
        tree.push(d);
      }
    }

    for (let t of tree) {
      adaptToChildrenList(t);
    }

    function adaptToChildrenList(o) {
      if (childrenListMap[o[config.id]] !== null) {
        o[config.childrenList] = childrenListMap[o[config.id]];
      }
      if (o[config.childrenList]) {
        for (let c of o[config.childrenList]) {
          adaptToChildrenList(c);
        }
      }
    }
    return tree;
  },

  arrayChangeKey(arr, key, is_rev) {
    let result = {};
    for(let i in arr){
      let item =arr[i];
      if (is_rev) {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      } else {
        result[item[key]] = item;
      }
    }
    return result;
  },
  translate(data){
    let lang=window.localStorage.getItem('language')
    if(lang=='zh') return data

    if(typeof data == "object"&&Array.isArray(data)){
      let list=[...data]
      let array=[]
      list.forEach(item=>{
        for(var i in item){

          if(i.substr(-3) =='_en' ){
            if(!item[i+'g']){
              item[i.slice(0,-3)] =item[i]
            }

          }

          if(i.substr(-4) =='_eng'){
            item[i.slice(0,-4)] =item[i]
          }

        }
      })

      return data
    }else if(typeof data == "object"&&!Array.isArray(data)){

      let obj={...data}

      for(var i in data){

        if(i.substr(-3) =='_en' ) {
          if(!obj[i+'g']){
            obj[i.slice(0,-3)] =obj[i]
        }
        }
        if(i.substr(-4) =='_eng' ){
          obj[i.slice(0,-4)] =obj[i]
        }
      }

      return obj
    }else {
      return data
    }
  }
}


