<template>
  <el-row class="WJgroupIntroduce-menuBox hidden-xs" type="flex" justify="center" align='center'>
    <el-col :xs="24" :sm="24" :md="24" :lg="23"  :xl="18">
      <div style="display: flex;justify-content: space-between;flex-direction: row;align-items: center;">
        <div class="flex">
          <img style="width: 24px;height: 24px;padding:0 17px;" src="@/assets/image/homeICON.png" alt="">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key='index'> {{item.name}}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>


      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :router="true">
      <!--  <el-menu-item index="/"  style="border-left:1px solid #AAAAAA ;border-right:1px solid #AAAAAA ;background-color:#fff">
          <template slot="title">
              <img style="width: 24px;height: 24px;padding:0 17px;" src="@/assets/image/icon1.png" alt="">
          </template>
        </el-menu-item> -->
      <!--  <el-menu-item :index="menu.path" class="me-el-menuOne">
          <template slot="title">
              <span slot="title">{{menu.name}}</span>
              <i class="el-icon-caret-right"></i>
          </template>
        </el-menu-item> -->
        <el-menu-item :index="item.path" v-for="(item,index) in menu.list" :key="index">
          <template slot="title">
            <!-- <a :href="item.path"> -->
              <span slot="title">{{item.name}}</span>
            <!-- </a> -->
          </template>
        </el-menu-item>
        <!-- <el-menu-item index="4">
         <template slot="title">
           <a href="/WJgroupDevelop">
           <span slot="title">发展历程</span>
           </a>
         </template>
       </el-menu-item> -->
        <!-- <el-menu-item index="5">
         <template slot="title">
           <span slot="title">资质荣誉</span>
         </template>
       </el-menu-item> -->
        <!-- <el-menu-item index="6">
         <template slot="title">
           <span slot="title">管理团队</span>
         </template>
       </el-menu-item> -->
      </el-menu>


      </div>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: "navMenu",
    components: {},
    props: {
      activeIndex: {
        type: String,
        default: ''
      },
      menu: {
        type: Object,
        default: () => {}
      },
      breadcrumb: {
        type: Array,
        default: () => {}
      }
    },
    data() {
      return {

      };
    },

    created() {},
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style scoped lang="less">
  .WJgroupIntroduce-menuBox {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    height: 60px;
    background-color: #fff;

  }

  .WJgroupIntroduce-menuBox .el-menu-demo {
    height: 60px;
  }

  .WJgroupIntroduce-menuBox .me-el-menuOne {
    width: 180px;
    text-align: center;
    background-color: #fff !important;
  }

  .WJgroupIntroduce-menuBox .me-el-menuOne .el-icon-caret-right {
    margin-left: 20px;
  }

  .WJgroupIntroduce-menuBox .el-menu-item {
    background-color: #FFFFFF;
  }

  .WJgroupIntroduce-menuBox .el-menu--horizontal>.el-menu-item.is-active {
    background-color: #fff;
    border-bottom: 0px;
    color: #A4825F!important;
    position: relative;
  }
  .WJgroupIntroduce-menuBox .el-menu--horizontal>.el-menu-item.is-active::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 12%;
    width: 76%;
    height: 2px;
    background-color:  #A4825F;
  }
  .WJgroupIntroduce-menuBox .breadcrumb {
    line-height: 60px;
  }
</style>
