import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/api/index.js'
import Directives from '@/directives'
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts'

import Video from 'video.js'
import 'video.js/dist/video-js.css'

import 'videojs-contrib-hls'
import ElementUI from 'element-ui';
import 'animate.css';
import "wowjs/css/libs/animate.css" // 巨坑... 此地方要引入两个css
//导入配置好的国际化语言包
import i18n from '@/utils/i18n' // Internationalization
import wow from 'wowjs'
Vue.prototype.$wow = wow
new wow.WOW().init()
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './assets/css/reset.less';
import header from "@/components/header.vue"
import footerb from "@/components/footer.vue"
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import navMenu from "@/components/navMenu.vue"
// Import Swiper styles  // import 'swiper/css';

Vue.component('navMenu', navMenu)
Vue.component('headert', header)
Vue.component('footerb', footerb)
Vue.component('swiper', swiper)
Vue.component('swiperSlide', swiperSlide)
Vue.prototype.$video = Video
Vue.use(dataV)
Vue.use(Directives)
// Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$echarts = echarts


import axios from '@/utils/axios'
Vue.prototype.$request = axios;
import init from '@/utils/init'
Vue.prototype.$init =init;
import utils from '@/utils/index'
Vue.prototype.$utils =utils;

//向上通知
Vue.prototype.$dispatch = function(eventName, value) {
  let parent = this.$parent;
  while (parent) {
    parent.$emit(eventName, value)
    parent = parent.$parent;
  }
}
//向下通知
Vue.prototype.$broadcast = function(eventName, value) {
  const broadcast = (children) => {
    children.forEach(child => {
      child.$emit(eventName, value);
      if (child.$children) {
        broadcast(child.$children)
      }
    })
  }
}

Vue.use(ElementUI, {
  size: 'medium', // set element-ui default size设置元素默认大小
  i18n: (key, value) => i18n.t(key, value)// 在注册Element时设置i18n的处理方法
})
//
//
// Vue.config.productionTip = false// 生产提示
//


// 设置根元素font-size
function setRem(){

  // 获取屏幕宽度
  var w = document.documentElement.clientWidth;
  // console.log(w,'屏幕寬度')
  // let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  // console.log(isMobile)
  if(w<=750){
    document.documentElement.style.fontSize = w / 10 + 'px';
  }else{
    document.documentElement.style.fontSize = w / 19.20 + 'px';
  }




}
setRem();
window.addEventListener("resize", setRem);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
