
export default {
  index: {
    Introduction: 'Introduction to the Group',
    honor: 'Qualifications and honors',
    team: 'Management team',
    contactUs: 'Contact Us',
  },
  introduce:{
    slogan:'Weishan cast good, Mingde Zhiyuan',
    subSlogan:'Vega Group is committed to exploring the needs of urban public life and space from the perspective of urban development',
    establish:'Established in {num}',
    staff:'With over {num} employees',
    custom:'Achieved over {num} customers',
    develop:'The total development volume has exceeded 400000 cubic meters',
    operate:'400000 square meters of commercial property fully self operated',
    cultureTitle:'Corporate culture',
    cultureSubtitle1:'mission',
    cultureSubtitle2:'idea',
    cultureSubtitle3:'values ',
    mission:'Development mission',
    missionSub1:'people-oriented',
    missionDesc1:'Outshine, finally lost the garden of spring. With "people" as the core, cultivating talents with Huai and holding instruments, faith and love, exploring potential and value, and promoting the flower of ideals.',
    missionSub2:'Share the same interests',
    missionDesc2:'Those who have the same aspirations are not afraid of mountains and oceans, and the ideal is the center of the ball, you come slowly and slowly, we meet unexpectedly, join hands and join together into a community of destiny.',
    missionSub3:'Honesty and gratitude',
    missionDesc3:'Honesty and gratitude for the foundation of the body, in their own, in the enterprise, in the community will be honest, the line will be loyal, the grace will return.',
    missionSub4:'people-oriented',
    missionDesc4:'The beauty of "ingenuity" is exquisite and innovative; The value of "ingenuity" lies in the details. With the "craftsman spirit" to keep pace with The Times, to refine skills, to surpass the self, to grasp the infinite, to use the moment to retain eternity.',
    idea:'Enterprise philosophy',
    ideaSub1:'A good act of harmony and a good work of perfection',
    ideaSub2:'Clear social morality, let the enterprise sustainable development',
    values:'Core values',
    valuesSub1:'Serving the society and developing ourselves is the direction of our efforts',
    valuesSub2:'Self-development, giving back to the community is our eternal theme'
  },
  develop:{
    title:'Memorabilia'
  },
  honor:{
    title:'Glories'
  },
  team:{
    title:'Team Profile',
    desc:'Gather a professional team to establish a standardized and efficient management system\n' +
      'The practical steps of a group of idealists'
  },
  property:{
    title:'Real Estate',
    href1:'Boutique Residence',
    href1Desc:'Build a fine reputation, cast a hundred years of foundation, and achieve the city dream of thousands of people."',
    href2:'Commercial property',
    href2Desc:'Let the business entertainment, let the consumer experience, let the brand internationalization, let the enjoyment of a station.',
    href3:'Property Services',
    href3Desc:'customer first, heart service。',
    idea:`WEIGA Real Estate has always adhered to the building concept of five products`,
    ideaSub:'Build high-standard products, fine products with artisan spirit, works of creators, master-level artworks, and market isolated products, let quality first, and cast the brand with strength.',
    ideaItem1:'Build high standard products',
    ideaItem2:'Artisan spirit of excellence',
    ideaItem3:'Creator\'s work',
    ideaItem4:'A master piece of art',
    ideaItem5:'market isolated products',
    introduction:'Industry introduction',
    introductionDesc:'With Wuhan WEIGA Real Estate Co., Ltd. established in 2004 as the beginning, relying on the group\'s leapfrog development pattern and focusing on Wuhan as the core, WEIGA Real Estate has gradually implemented the strategic transformation from traditional real estate development to the diversified development direction of "ecological new town", "cultural and sports new town" and "characteristic town". In recent years, the total development amount of WEIGA Real Estate has exceeded 3 million square meters, and the products cover many types of business types such as residential, commercial, office, and hotel. Its 400,000 square meters of commercial properties are fully self-owned construction and operation, and the capital accumulation is strong.',
    position:'Strategic positioning',
    position1:'Ecological New Town',
    position2:'Sports New Town',
    position3:'Characteristic Town',
    positionTypeTitle1:`dwelling`,
    positionTypedesc1:`Quality casting, fully equipped`,
    positionTypeTitle2:`Office building`,
    positionTypedesc2:`Core location, high-end elegance`,
    positionTypeTitle3:`groggery`,
    positionTypedesc3:`Warm and comfortable, elegant and pleasant`,
    positionTypeTitle4:`commerce`,
    positionTypedesc4:`Leisure entertainment, taste of life`,
    positionTypeAll:` Industrial<br>coverage`,
    moreTips:'Stay tuned for more content',
    purposeTitle:'Industry Purpose',
    purposeDesc1:'Pursuing service quality first, ',
    purposeDesc2:'shaping enterprise brand',
    purposet1:'Service with temperature',
    purposetd1:'Cultivation and meticulous cultivation',
    purposet2:'Personalized projects',
    purposetd2:'not to follow the beaten track',
    purposet3:'A managed team',
    purposetd3:'refine on',
    purposet4:'Intelligent hardware',
    purposetd4:'Accompanying You Forward',
    houseAddress:'Project address: ',
    housePhone:'contact information: ',
  },
  commerce:{
    title:'Trade',
    brandTitle:'Product Display',
    brand:'Co-branding',
    space:'WEIGA Space',
    spaceDesc:'WEIGA SPACE was officially born in Wuhan, with the starting point of "Prophet product value, insight into market potential, creation of business concept, development model fission", starting from this, infinite fission, continuous extension and enrichment of Weiga Rode universe space elements. Driven by the core culture and brand concept of luxury goods, we discover quality and value through the exquisite combination of product matching.'

  },
  entertainment:{
    title:'Culture and Entertainment',
    advantages:'Core advantages'
  },
  park:{
    title:'Industrial Park',
    href1:'Sicily Automotive Industrial Park',
    href2:'Mingfang Industrial Park',
    experienceTitle:'HUB type experience center',
    experienceDesc:'Space·quality·culture·ecology·value',
    introduceTitle:'Park introduction',
    introduceDesc:'With a total investment of 1 billion yuan, Sicily Automobile Industrial Park is planned to be built into a large-scale comprehensive automobile industrial park integrating sports and leisure, entertainment, business clusters, restaurants and hotels as well as research and development centers. Combining superior geographical location, convenient transportation, strong humanistic atmosphere and mature business supporting facilities, it will build an automobile industrial park of automobile industry cluster in an all-round way.',
    positioningTitle:'Industrial orientation',
    positioningDesc:'The automobile industry and after-sales service will be combined with Maotan Park, military transfer Park and commercial supporting services to create a modern comprehensive park integrating industry, science and technology, leisure, entertainment and service.',
    targetTitle:'Construction objective',
    target1:'Improve regional business forms',
    target2:'enhance economic vitality',
    target3:'increase employment opportunities',
    target4:'drive economic development',
    advantage:'Park advantage',
    advantagName1:'estate',
    advantagDesc1:`The current functions of the surrounding areas are mainly residential, educational, and commercial;<br>The development trend of the area is excellent, with a series of high-quality real estate development projects facing multiple universities.`,
    advantagName2:'Traffic',
    advantagDesc2:`The road network in the area is developed and the transportation is convenient.<br>Wuhan is the core automotive industry base for new energy vehicles and medium and high-end brands.`,
    advantagName3:'Location',
    advantagDesc3:`It has outstanding advantages in hub layout, market coverage and geographical location`,
    advantagName4:'Consumer group',
    advantagDesc4:`The population inflow is large, the structure is young, and the surrounding permanent residents and college students are easy to leverage the automobile consumption market`,
    serviceTitle:'Park services',
    service1:'Automotive Services',
    service2:'Sports & Leisure',
    service3:'Children\'s Play',
    service4:'Theme Hotel',
    service5:'Business Ecology',
    service6:'Entrepreneurship Center',
    characteristicTitle:'Park characteristics',
    characteristic1:'Covering a full range of industrial chains, including new car sales, used car trading, vehicle maintenance and modification, imported car exhibition, auto parts sales, etc.',
    characteristic2:'To provide customers with business meetings, sports, recreation, shopping, accommodation and other one-stop services, to bring customers a better immersive experience.',
    characteristic3:'Create a multi-functional modern park integrating sports, amusement, business, hotel and research and development center.',
    characteristic4:'The introduction of sports, science and technology, health, amusement, fashion facilities and equipment, so as to improve people\'s living environment and improve the quality of life.',
    characteristic5:'As the first new industrial park in Wuhan with military transformation and movement as the core, it will bring new opportunities and vigorous development to the economy and related industries of Wuhan and its surrounding areas. ',
    brand:'Brand entry',
    mfSlogan:'One hundred thousand square one-stop 4S shop cluster',
    mfSubSlogan:'The never-ending journey of automobile business.',
    mftarget1:'A unique environment',
    mftarget2:'Creating organic growth',
    mftarget3:'A natural heritage',
    mftarget4:'Unlimited possibilities stimulated',
    mftarget5:'High standard, high starting point planning and construction',
    mftarget6:'The pursuit of excellence in the driving force of development',
    mftarget7:'Enduring management quality',
    mftarget8:'Unique and keen development perspective',
    mfIntroduce1:'Wuhan Mingfang Economic & Trade Co., Ltd. was established in 2002. As the earliest company of WEIGA Group, Mingfang Company has continuously contributed to the growth and development of the group. After 20 years of hard work, Mingfang has always adhered to the planning and construction of high standards and high starting point, inherited the development motive force of WEIGA Group\'s pursuit of excellence, adhered to the timeless management quality, and constantly promoted the development of Wuhan\'s automobile industry with its unique perspective. At present, it has become a comprehensive automobile trade market with certain influence in Wuhan.',
    mfIntroduce2:'Tieji Automobile Exhibition and Sales Center is the only and largest automobile trade service market in the second Ring road of Wuhan, with a unique environment to create an organic growth of the automobile business city, where the automobile exhibition and sales and multi-form urban complex are newly defined, leading the era of automobile city and enjoying the automobile life. It has now settled in well-known international automobile brands such as Mercedes-Benz, BMW and Lincoln, and is a special support project of Wuhan Municipal Government for urbanization development.',

  },
  news:{
    search:'search',
    yearPlaceholder:'Filter Year',
    keywordsPlaceholder:'Please enter content',
    creatTime:'Release time: ',
    source:'source: Vega Group ',
    prev:'Previous article',
    prevTips:'This is already the first article',
    next:'Next article',
    nextTips:'It\'s already the last one'
  },
  school:{
    title:'Talent Cultivation',
    slogan:'In January 2021, WEIGA Group launched the WEIGA Cadre Training School. The establishment of WEIGA Cadre Training School is another milestone in the development process of WEIGA Group, an innovative move to effectively implement the group\'s talent strategy planning, and a new cradle for incubating business talents, management talents, and cross shaped talents.',
    desc1:'Diligent in learning',
    desc2:'Brave to make progress',
    desc3:'Dare to take responsibility',
    desc4:'Willing to contribute',
    desc5:'be strict with oneself',
    classShow:'Class presentation',
    more:'more',
    student:'Excellent student'

  },
  joinUs:{
    title:'Contact Us',
    search: "Search for position",
    placeholder:'Please enter content',
    joinEmail:'If you are interested in the relevant position, please send your resume to the recruitment email:',
    joinEmailTips:'Suggest naming the email in this format: "City+Job Position+Name"',
    joinPhone:'All candidate information will be carefully screened and strictly confidential. Recruitment consultation hotline:',
    companyType:'Company type: ',
    companyName:'Company Name: ',
    all:'whole',
    phone:'contact information: ',
    apply:'Apply for position',
    formName:'full name',
    formNameT:'Please enter your name',
    formPhone:'telephone',
    formPhoneT:'Please enter telephone',
    formPost:'position',
    formPostT:'Please enter position',
    annex:'Support DOC, PDF file size not exceeding 10M',
    annextIip:'Upload resume attachment',
    annexbTN:'Resume attachment upload failed',
    success:'Resume successfully submitted',
    btnC:'Cancel',
    btnS:'Confirm',
    Media:'New Media Matrix',
  },
  menu:{
    WeChat:'Scan code and add WeChat',
    officialAccount:'Scan code and add official account',
    filings:'Filing number',
    chinese:'简体中文',
    engish:'English',
    index:'HOMEPAGE',
    group:'WEIGA',
    layout:'INDUSTRIES',
    news:'NEWS',
    recruit:'TALENT',
    Introduction: 'Company Profile',
    Message:'Chairman Message',
    develop:'Memorabilia',
    honour:'Glories',
    team:'Team Profile',
    Property:'Real Estate',
    Commerce:'Trade',
    entertainment:'Culture and Entertainment',
    culture:'Cultural and entertainment',
    park:'Industrial Park',
    trends:'Trends',
    information:'Enterprise',
    Media:'Media coverage',
    talented:'Talent Concept',
    school:'Talent Cultivation',
    joinUs:'Contact Us',
    detail:'Details',
    cinema:'Digital Cinema',
    Play:'Amusement Cool Play',
    education:'WEIGA Education'
  }
}
